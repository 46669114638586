import React, { lazy, Suspense, useEffect } from 'react'
import './App.css'
import Intro from './components/Intro'
import About from './components/About'
import Projects from './components/Projects/Projects'
import Contact from './components/Contact'
import { Element } from 'react-scroll'
import { Regular } from './components/Typography'
import ReactGa from 'react-ga'

const Header = lazy(() => import('./components/Header/index'))

function App() {
  useEffect(() => {
    ReactGa.initialize('UA-145732904-1')
    ReactGa.pageview('/')
  }, [])

  return (
    <Suspense
      fallback={'Loading...'}
      style={{
        width: ' 100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className='appWrapper'
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: 'whitesmoke',
        }}
      >
        <div
          className='appContainer'
          style={{
            maxWidth: 940,
            width: '100%',
            background: 'white',
            boxShadow: ' rgba(0, 0, 0, 0.2) 0px 0px 4px 0px            ',
          }}
        >
          <Header />
          <Element
            name='intro'
            className='element'
            style={{ position: 'relative' }}
          >
            <Intro />
          </Element>
          <Element
            name='about'
            className='element'
            style={{ position: 'relative' }}
          >
            <About />
          </Element>
          <Element name='projects' className='element'>
            <Projects />
          </Element>
          <Element name='contact' className='element'>
            <Contact />
          </Element>
          <div id='footer'>
            {' '}
            <a
              href='https://angelapatrinou.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Regular style={{ fontFamily: 'Open Sans', fontSize: '1.2em' }}>
                {' '}
                Web design & development by{' '}
                <span
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    fontFamily: 'Open Sans',
                  }}
                >
                  Angela Patrinou
                </span>{' '}
                © {new Date().getFullYear()}
              </Regular>
            </a>
          </div>
          {/* <p>Things to implement</p>
      <p>Step 3. Make a PWA out of it (half done)</p>
      <p>Step 4. Lazy loading (done)</p>
      <p>Step 5. Translation</p>
      <p>Step 6. Responsiveness(done) - To do: for big screens</p>
      <p>Step 7. connect Header navigation items to places in the screen (done)</p>
      
      I am doing this in order to:
      - have google analytics 
      - implement routing between different pages. 
      - have different pages with services, showcase projects etc. 
      
      */}
        </div>
      </div>
    </Suspense>
  )
}

export default App
