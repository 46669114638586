import React from 'react'
import {
  Title,
  ContactBlock,
  ContactRegular,
  IconsContainer,
  FacebookContainer,
  FacebookIcon,
} from './elements'
const Contact = () => (
  <ContactBlock>
    <Title>Επικοινωνία</Title>
    <ContactRegular>kolokythai@gmail.com</ContactRegular>
    <ContactRegular>6946703311</ContactRegular>
    <ContactRegular>Αρτεμισίας 153</ContactRegular>
    <ContactRegular>71 601</ContactRegular>
    <ContactRegular>Νέα Αλικαρνασσός, Ηράκλειο</ContactRegular>
    <IconsContainer>
      <FacebookContainer>
        <FacebookIcon
          href="https://www.facebook.com/IoannaKolokythaPsychologist"
          target="_blank"
        />
      </FacebookContainer>
    </IconsContainer>
  </ContactBlock>
)

export default Contact
